/*
Display a Person associate to a Document
*/

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ShowPerson extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
    }

    handleDestroy(event) {
        this.props.onDelete(this.props)
    }


    render() {
        const {relation} = this.props;
        console.log(relation)
        return (
                <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="h5 text-uppercase mb-1">
                                    { relation.name}
                                </div>
                                { relation.dni_number && (
                                  <div className="text-xs mb-0 font-weight-bold text-gray-800">
                                    <strong> Nº Doc.: </strong>{ relation.dni_number }
                                  </div>
                                )}

                                { relation.phone && (

                                  <div className="text-xs mb-0 font-weight-bold text-gray-800">
                                      { relation.phone}
                                  </div>
                                )}
                                { relation.charge_name && (
                                  <div className="text-xs mb-0 font-weight-bold text-gray-800">
                                      { relation.charge_name}
                                  </div>
                                )}
                                { relation.role && (
                                  <div className="text-xs badge badge-info">
                                      { relation.role}
                                  </div>
                                )}
                                { relation.description && (
                                  <div className="text-xs text-gray-800">
                                      { relation.description}
                                  </div>
                                )}
                            </div>
                            <div className="col-auto">
                                <button
                                      onClick={this.handleDestroy}
                                      className="btn btn-outline-danger btn-sm"
                                  >
                                      <i className="fas fa-unlink"></i>
                                  </button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default ShowPerson

ShowPerson.propTypes = {
    relation: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
}
