/*
Display a Person associate to a Document
*/

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ShowDocument extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
    }

    handleDestroy(event) {
        const confirmation = confirm('Estas Seguro que queres eliminar a la Persona')
        if (confirmation) {
            this.props.onDelete(this.props.relation.id)
        }
        event.preventDefault();
    }


    render() {
        const {relation} = this.props;
        return (
                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-secondary border-bottom-secondary shadow h-100 py-2">
                      <div className="card-body">
                          <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                  <div className="h5 text-uppercase mb-1">
                                      <a className="text-success" href={relation.url_document}>{ relation.name}</a>
                                  </div>
                                  <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                    <strong>Tipo: </strong>{ relation.type_document }
                                  </div>
                                  <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                    <strong>Sub Tipo: </strong>{ relation.subtype_document }
                                  </div>
                                  <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                    <strong>Número: </strong>{ relation.number }
                                  </div>
                                  <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                    <strong>Fecha: </strong>{ relation.date }
                                  </div>
                                  <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                    <strong>Archivo: </strong>
                                      { relation.url && (
                                      <a href={ relation.url } target="_blank">Descargar </a>
                                    )}

                                  </div>

                              </div>
                              <div className="col-auto">
                                  <button
                                        onClick={this.handleDestroy}
                                        className="btn btn-outline-danger btn-sm"
                                    >
                                        <i className="fas fa-unlink"></i>
                                    </button>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
        )
    }
}

export default ShowDocument

ShowDocument.propTypes = {
    relation: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
}
