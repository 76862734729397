import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
//import Show from '../documents/Show'
import BrowseDocuments from '../documents/BrowseDocuments'
import ShowDocument from '../documents/ShowDocument'

class ManageDocuments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            document_relationships: [],
        }
        this.destroyItem = this.destroyItem.bind(this)
        this.uniqueId = this.uniqueId.bind(this)
        this.addDocument = this.addDocument.bind(this)
    }
    componentDidMount() {
        if(this.props.document_relationships){
            const {document_relationships} = this.props
            let el = this
            document_relationships.forEach(function(item, index) {
                if(!item.react_id){
                    item.react_id = el.uniqueId()
                }
            })
            this.setState({ document_relationships })
        }
    }

    uniqueId () {
        return (Date.now() + Math.floor(Math.random() * 1000)) * -1
    }

    destroyItem(id){
      //const confirmation = confirm("¿Estas seguro de eliminar la relación?")
      //if(confirmation){
        let that = this
        $.ajax({
          type: "POST",
          url: `\\dashboard\\document_relationships\\${id}\\del_relation`,
          success: function( data, textStatus, jqXHR){
            let filteredItems = that.state.document_relationships.filter(item =>
              item.id !== id);
              that.setState({
               document_relationships: filteredItems
              })
          },
          dataType: 'json'
        });
      //}
    }

    addDocument(doc){
        let data = {
            owner_uuid: this.props.uuid,
            owner_type: this.props.className,
            charge_uuid: this.props.uuid,
            charge_name: this.props.charge_name,
            format: this.props.format
        }
        let el = this
        $.ajax({
          type: "POST",
          url: `\\dashboard\\documents\\${doc.id}\\add_relation`,
          data: data,
          dataType: 'json',
          success: function( data, textStatus, jqXHR){
            data.react_id = el.uniqueId()
            const document_relationships = [data, ...el.state.document_relationships]
            el.setState({ document_relationships })
          },
          error: function (request, status, error) {
            let msj = JSON.parse(request.responseText)
            alert(msj.error);
          }
        });

    }

    render() {
            return (
            <React.Fragment>
                  <div className="row">
                    { this.state.document_relationships.map((item, index) => (
                        <ShowDocument
                            relation={ item }
                            onDelete={ this.destroyItem }
                        />
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                        <BrowseDocuments
                            documents={[]}
                            addDocument={this.addDocument}
                            options_type_documents={this.props.options_type_documents || []}
                            buttonTitle="Asociar Documentos al Cargo"
                            key="browseDocuments"
                            withInitialDocuments={true}
                        />
                      </div>
                  </div>

            </React.Fragment>
        )
    }
}

export default ManageDocuments

ManageDocuments.propTypes = {
    document_relationships: PropTypes.array.isRequired,
    uuid: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    charge_name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired
}
