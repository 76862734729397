
import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'
import axios from 'axios';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

class FilterIndexDocuments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type_document_id: "",
            subtype_document_id: "",
            number: "",
            year: "",
            emission_at: "",
            cache_subtype_documents: {},
            isLoading: false,
            startYear: props.startYear || 2000,
            datatable: null
        }
        this.handleChangeTypeDocument = this.handleChangeTypeDocument.bind(this)
        this.handleChangeSubtypeDocument = this.handleChangeSubtypeDocument.bind(this)
        this.handleChangeNumber = this.handleChangeNumber.bind(this)
        this.handleChangeYear = this.handleChangeYear.bind(this)
        this.handleChangeEmissionAt = this.handleChangeEmissionAt.bind(this)
        this.rangeYear = this.rangeYear.bind(this)
        this.filter_values = this.filter_values.bind(this)
        this.datatable_lang_es = this.datatable_lang_es.bind(this)
    }

    componentDidMount() {
      //this.fetchSubtypeDocuments(this.state.type_document_id)
      let that = this
      let datatable = $(this.props.table_id).DataTable({
          language: datatable_lang_es,
          processing: true,
          serverSide: true,
          scrollX: false,
          columnDefs: [
            { className: "td-wrap-word", targets: [3, 5] },
            {targets: [6, 7], sortable: false }
          ],
          ajax: {
            url: $('#index-documents').data('url'),
            method: 'GET',
            data: function(d) {
              d.filters = that.filter_values();
              return d;
            }
          },
          responsive: true,
          iDisplayLength: 25,
          sPaginationType: 'full_numbers',
          dom: 'T<"clear">lfrtpi',
          order: [[2, 'desc']],
      })
      this.setState({datatable})
    }

    rangeYear(){
      const startYear = this.state.startYear
      const currentYear = new Date().getFullYear()
      return Array.from(Array(currentYear - startYear + 1).keys()).map(x => currentYear - x)
    }

    handleChangeTypeDocument(event){
      this.setState({ type_document_id: event.target.value }, () => {
          this.state.datatable.ajax.reload()
      });
      this.fetchSubtypeDocuments(event.target.value)
    }

    handleChangeSubtypeDocument(event){
      this.setState({subtype_document_id: event.target.value}, () => {
          this.state.datatable.ajax.reload()
      });
    }

    handleChangeNumber(event) {
      this.setState({number: event.target.value}, () => {
          this.state.datatable.ajax.reload()
      });
    }

    handleChangeYear(event) {
      this.setState({year: event.target.value}, () => {
          this.state.datatable.ajax.reload()
      });
    }

    handleChangeEmissionAt(value) {
      if(value.length > 10){
        this.setState({emission_at: value}, () => {
            this.state.datatable.ajax.reload()
        });
      }
    }

    fetchSubtypeDocuments(type_document_id){
      if (type_document_id) {
        axios.get(`\\dashboard\\type_documents\\${type_document_id}\\subtype_documents`)
        .then(res => {
          const std = res.data;
          let cache_subtype_documents = this.state.cache_subtype_documents
          let subtype_document_id = ""
          cache_subtype_documents[type_document_id] = std
          this.setState({ cache_subtype_documents,  subtype_document_id });
          //this.props.callback({type_document_id: this.state.type_document_id, subtype_document_id: this.state.subtype_document_id, })
        })
      }
      else{
          this.setState({"subtype_document_id": ""});
      }
    }

    getSubtypeDocuments() {
      return this.state.cache_subtype_documents[this.state.type_document_id] || []
    }

    filter_values() {
      return {
        type_documents: this.state.type_document_id,
        subtype_documents: this.state.subtype_document_id,
        number: this.state.number,
        emission_at: this.state.emission_at,
        year: this.state.year,
      };
    }

    datatable_lang_es (){
       return {
        "sProcessing":     "Procesando...",
        "sLengthMenu":     "Mostrar _MENU_ registros",
        "sZeroRecords":    "No se encontraron resultados",
        "sEmptyTable":     "Ningún dato disponible en esta tabla",
        "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
        "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
        "sInfoPostFix":    "",
        "sSearch":         "Buscar:",
        "sUrl":            "",
        "sInfoThousands":  ",",
        "sLoadingRecords": "Cargando...",
        "oPaginate": {
            "sFirst":    "Primero",
            "sLast":     "Último",
            "sNext":     "Siguiente",
            "sPrevious": "Anterior"
        },
        "oAria": {
            "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
        }
      }
    }

    render() {
      return (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="control-label"><strong>Tipo</strong></label>
              <select className="form-control filter form-control-sm" onChange={ this.handleChangeTypeDocument }>
                <option value="" key="option_type_documents_0"></option>;
                {this.props.type_documents.map((type_document) => {
                  return <option value={type_document[1]} key={`"type_documents_${type_document[1]}"`}>{type_document[0]}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="control-label"><strong>SubTipo</strong></label>
              <select value={this.state.subtype_document_id} onChange={this.handleChangeSubtypeDocument} className="form-control filter form-control-sm" name='filter_subtype_documents'>
                <option value="" key="option_subtype_documents_0"></option>;
                {this.getSubtypeDocuments().map((std) => {
                  return <option value={std.id} key={`"subtype_documents_${std.id}"`}>{std.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <label className="control-label"><strong>Número</strong></label>
            <input type="text" value={this.state.number} onChange={this.handleChangeNumber} className="form-control form-control-sm" />
          </div>
          <div className="col">
            <label className="control-label"><strong>Año</strong></label>
            <select value={this.state.year} onChange={this.handleChangeYear} className="form-control form-control-sm">
                <option value=""></option>;
                {this.rangeYear().map((std) => {
                  return <option value={std} key={`"subtype_documents_${std}"`}>{std}</option>;
                })}
              </select>
          </div>
          <div className="col">
            <label className="control-label"><strong>Rango de Emisión</strong></label>
             <Flatpickr
                className="form-control form-control-sm"
                options={{
                  "mode": "range",
                  "dateFormat": "Y-m-d",
                  "altFormat": "d-m-Y",
                  "altInput": true,
                  "allowInput": true,
                  "locale": Spanish
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  this.handleChangeEmissionAt(dateStr);
                }}
              />
          </div>
        </div>
      )
    }
}
FilterIndexDocuments.propTypes = {
    type_documents: PropTypes.array,
    table_id: PropTypes.string,
}

export default FilterIndexDocuments

