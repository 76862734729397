import React from 'react'
import PropTypes from 'prop-types'
import ShowPerson from './ShowPerson'
import Spinner from '../Spinner'
import {DelayInput} from 'react-delay-input';
import axios from 'axios';

// Product Sample - Personas
class BrowsePeople extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            people: [],
            isLoading: false,
            disable_ids: props.disable_ids || [],
            roles_document: []
        }
        this.handleChangeTerm = this.handleChangeTerm.bind(this)
        this.selectPerson = this.selectPerson.bind(this)
    }
    componentDidMount() {
      axios.get(`\\dashboard\\role_document_relationships.json?json_data=true`)
      .then(res => {
        const roles_document = res.data;
        this.setState({ roles_document });
      })
    }

    handleChangeTerm(event){
      let el = this
      const data = {term: event.target.value}
      $.ajax({
        type: "GET",
        url: `\\dashboard\\people\\search.json`,
        data: data,
        success: function( data, textStatus, jqXHR){
          const people = data
          el.setState({ people })
        },
        dataType: 'json'
      });
    }

    selectPerson(person_id) {
      const key=`#charge_people_${person_id}`
      const charge_id = $(key).val()
      const role = $(`#role_document_${person_id}`).val()
      const description = $(`#description_${person_id}`).val()
      this.setState(previousState => ({
          disable_ids: [...previousState.disable_ids, person_id]
      }));
      this.props.addPerson({
        person_id: person_id,
        charge_id: charge_id,
        role_document_relationship_id: role,
        description: description
      })
    }

    isDisabled(id){
      let found = this.props.getDisableIds().filter(person_id =>
        person_id == id);
      return (found === undefined || found == null || found.length <= 0) ? false : true;
    }

    render() {
        return (
          <React.Fragment>
            <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#browseModalDocuments">
              Agregar Persona
            </button>
            <div className="modal fade" tabIndex="-1" role="dialog" id="browseModalDocuments">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Seleccionar Persona</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                          <div className="row">
                            <div className="col-md-3 form-group">
                              <label className="control-label">Buscar</label>
                              <DelayInput
                                minLength={2}
                                delayTimeout={ 300 }
                                className="form-control"
                                key="browse_person_term"
                                onChange={ this.handleChangeTerm } />
                            </div>
                            <div className="col-lg-12">
                              {
                                this.state.people.map((person) => {
                                  if(!this.isDisabled(person.id)){
                                    return (
                                      <div className="card border border-primary mb-2" key={"row_person_" + person.id }>
                                         <div className="card-header">
                                          <strong>{ person.fullname }</strong>
                                        </div>
                                        <div className="card-body">
                                          <div className="form-row">
                                            <div className="col-md-6">
                                              <select className="form-control form-control-sm" id={`charge_people_${person.id}`}>
                                                <option value="">-- Seleccionar Cargo --</option>
                                                {
                                                  person.charge_people.map((charge) => (
                                                    <option value={charge.id}>
                                                      {charge.dependency} {charge.type_charge} {charge.charge}
                                                    </option>
                                                  ))
                                                }
                                              </select>
                                            </div>
                                            <div className="col-md-6">
                                              <select className="form-control form-control-sm" id={`role_document_${person.id}`}>
                                                <option value="">-- Seleccionar Rol --</option>
                                                {
                                                  this.state.roles_document.map((rol) => (
                                                    <option value={rol.id}>
                                                      {rol.name}
                                                    </option>
                                                  ))
                                                }
                                              </select>
                                            </div>
                                            </div>
                                            <div className="form-group mt-2">
                                              <textarea className="form-control" id={`description_${person.id}`}></textarea>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                          <button className="btn btn-sm btn-success" onClick={(event) => this.selectPerson(person.id)} style={{ minWidth: "100%"}}>
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                            Agregar
                                            </button>
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </div>




                          </div>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
    }
}
export default BrowsePeople
