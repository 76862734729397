import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
//import Show from '../documents/Show'
import BrowseDocuments from '../documents/BrowseDocuments'
import ShowDocument from '../documents/ShowDocument'

class SimpleManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            documents: [],
        }
        this.destroyItem = this.destroyItem.bind(this)
        this.addDocument = this.addDocument.bind(this)
    }
    inputName() {
      return this.props.inputName || "documents[id][]"
    }
    componentDidMount() {
        if(this.props.documents){
            const {documents} = this.props
            let el = this
            documents.forEach(function(item, index) {
                if(!item.react_id){
                    item.react_id = item.id
                }
            })
            this.setState({ documents })
        }
    }

    destroyItem(id){
      let filteredItems = this.state.documents.filter(item =>
        item.id !== id);
        this.setState({
         documents: filteredItems
        })
    }

    addDocument(document){
      const documents = [document, ...this.state.documents]
      this.setState({ documents })
    }

    render() {
            return (
            <React.Fragment>
                  <div className="row">
                    { this.state.documents.map((item, index) => (
                        <ShowDocument
                            relation={ item }
                            onDelete={ this.destroyItem }
                        />
                    ))}
                    { this.state.documents.map((item, index) => (
                        <HiddenValue
                          name={this.inputName()}
                          value={item.id}
                        />
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                        <BrowseDocuments
                            documents={[]}
                            addDocument={this.addDocument}
                            options_type_documents={this.props.options_type_documents || []}
                            buttonTitle="Seleccionar Documentos"
                            key="SimpleManager"
                            withInitialDocuments={true}
                        />
                      </div>
                  </div>

            </React.Fragment>
        )
    }
}

class HiddenValue extends React.Component {
    render() {
        var name = this.props.name;
        var value = this.props.value;
        return (
          <input type='hidden' name={name} value={value}/>
        )
    }
}
export default SimpleManager

SimpleManager.propTypes = {
    documents: PropTypes.array,
}
