/*
Display a Person associate to a Document
*/

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ShowPerson from './ShowPerson'
import BrowsePeople from './BrowsePeople'

class ListPeople extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relations: props.relations
        };
        this.handleDestroy = this.handleDestroy.bind(this)
        this.addPerson = this.addPerson.bind(this)
        this.selectedIds = this.selectedIds.bind(this)
    }

    handleDestroy(person) {
        const confirmation = confirm('Estas Seguro que queres eliminar a la Persona')
        if (confirmation) {
          let el = this
          let data = {
              document_person_id: person.relation.id,
          }

          $.ajax({
            type: "POST",
            url: `\\dashboard\\documents\\${this.props.parent_id}\\del_person`,
            data: data,
            success: function( data, textStatus, jqXHR){
              const filteredItems= el.state.relations.filter(item =>
                  item.id !== person.relation.id);
              el.setState({
                  relations: filteredItems
              })
            },
            dataType: 'json'
          });

        }
        event.preventDefault();
    }

    addPerson(data) {
      //let data = {
      //  person_id: person_id,
      //  charge_id: charge_id,
      //}
      let el = this
      $.ajax({
        type: "POST",
        url: `\\dashboard\\documents\\${this.props.parent_id}\\add_person.json`,
        data: data,
        dataType: 'json'
      }).done(function( data){
          const relations = [data, ...el.state.relations]
          el.setState({ relations: relations })
        }).
        fail(function( jqXHR, textStatus, errorThrown ){
          alert(jqXHR.responseJSON.error[0]);
        });
    }

    selectedIds(){
      return this.state.relations.map((person) => person.person_id)
    }

    render() {
        return (
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="float-right">
                      <BrowsePeople
                        addPerson={this.addPerson}
                        key="ListPerson_BrowsePeople"
                        getDisableIds={this.selectedIds}
                      />
                    </div>
                  </div>
                  { this.state.relations.map((relation, index) => (
                    <div className="col-xl-3 col-md-6 mb-4" key={"list_relations_" + index }>
                      <ShowPerson
                          relation={ relation }
                          onDelete={this.handleDestroy}
                          key={`relation_${relation.id}`}
                      />

                    </div>

                    ))
                  }
                </div>
        )
    }
}

export default ListPeople

ListPeople.propTypes = {
    relations: PropTypes.array.isRequired,
    parent_id: PropTypes.string.isRequired
}
