import React from 'react'
import PropTypes from 'prop-types'
import ItemForm from './ItemForm'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'

// Product Sample - Type of User
class Forms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rules: [],
            isLoading: false,
            errorMessage: null,
            total: 0,
        }
        this.addRule = this.addRule.bind(this)
        this.deleteRule = this.deleteRule.bind(this)
        this.handleErrors = this.handleErrors.bind(this)
        this.clearErrors = this.clearErrors.bind(this)
    }

    componentDidMount() {
        if(this.props.rules){
            const rules = this.props.rules
            rules.forEach(function(rule, index) {
                if(!rule.id){
                    rule.id = (Date.now() + Math.floor(Math.random() * 10)) * -1
                }
            })
            this.setState({ rules: rules })
        }
    }

    addRule(event) {
        const rule = {
            id: (Date.now() + Math.floor(Math.random() * 10)) * -1,
            name: "",
            description: "",
            condition: "",
            new_rule: true
        }
        const rules = [rule, ...this.state.rules]
        this.setState({ rules })
        event.preventDefault();
    }

    deleteRule(obj) {
        const filteredItems= this.state.rules.filter(rule =>
            rule.id!==obj.rule.id);
        this.setState({
            rules: filteredItems
        })
    }

    handleErrors(errorMessage) {
        this.setState({ errorMessage })
    }

    clearErrors() {
        this.setState({
            errorMessage: null,
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.errorMessage && (
                    <ErrorMessage errorMessage={this.state.errorMessage} />
                )}
                {!this.state.isLoading && (
                    <React.Fragment>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card shadow mb-4 border-left-primary">
                              <div className="d-flex">
                                <div className="p-2"><h6 className="ml-3 mt-3 font-weight-bold text-primary"> Reglas de Subscripción</h6></div>
                                <div className="ml-auto p-2"><button className="mr-3 mt-3 btn btn-sm btn-success" onClick={this.addRule}>Agregar</button></div>
                              </div>
                              <div className="card-body">
                                        {this.state.rules.map((rule, index) => (
                                            <ItemForm
                                                key={rule.id.toString()}
                                                rule={rule}
                                                field_name={this.props.rule_field_name}
                                                handleErrors={this.handleErrors}
                                                clearErrors={this.clearErrors}
                                                onDelete={this.deleteRule}
                                                rulesConditions={this.props.rules_conditions}
                                                />
                                        ))}
                              </div>
                            </div>
                          </div>
                        </div>
                    </React.Fragment>
                )}
                {this.state.isLoading && <Spinner />}
            </React.Fragment>
        )
    }
}

Forms.propTypes = {
  rules_conditions: PropTypes.array
}

export default Forms
