
import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'
import axios from 'axios';

class TypeDocumentSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subtype_documents: {},
            type_document_id: props.type_document_id,
            subtype_document_id: props.subtype_document_id || "",
            isLoading: false,
            errorMessage: null,
        }
        this.handleChangeTypeDocument = this.handleChangeTypeDocument.bind(this)
        this.handleChangeSubtypeDocument = this.handleChangeSubtypeDocument.bind(this)
    }

    componentDidMount() {
      this.fetchSubtypeDocuments(this.state.type_document_id)
    }

    handleChangeTypeDocument(event){
      this.setState({ type_document_id: event.target.value });

      this.fetchSubtypeDocuments(event.target.value)
    }

    handleChangeSubtypeDocument(event){
      this.setState({subtype_document_id: event.target.value});
    }

    fetchSubtypeDocuments(type_document_id){
      if (type_document_id) {
        axios.get(`\\dashboard\\type_documents\\${type_document_id}\\subtype_documents`)
        .then(res => {
          const std = res.data;
          let subtype_documents = this.state.subtype_documents
          let subtype_document_id = ""
          subtype_documents[type_document_id] = std
          this.setState({ subtype_documents,  subtype_document_id });
          this.props.callback({type_document_id: this.state.type_document_id, subtype_document_id: this.state.subtype_document_id, })
        })
      }
    }
    getSubtypeDocuments() {
      return this.state.subtype_documents[this.state.type_document_id] || []
    }
    render() {
      return (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="control-label"><strong>Tipo</strong></label>
              <select className="form-control filter form-control-sm" name='filter_type_documents' key='filter_type_documents' onChange={ this.handleChangeTypeDocument }>
                <option value="" key="option_type_documents_0"></option>;
                {this.props.type_documents.map((type_document) => {
                  return <option value={type_document[1]} key={`"type_documents_${type_document[1]}"`}>{type_document[0]}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="control-label"><strong>SubTipo</strong></label>
              <select value={this.state.subtype_document_id} onChange={this.handleChangeSubtypeDocument} className="form-control filter form-control-sm" name='filter_subtype_documents' key='filter_type_documents'>
                <option value="" key="option_subtype_documents_0"></option>;
                {this.getSubtypeDocuments().map((std) => {
                  return <option value={std.id} key={`"subtype_documents_${std.id}"`}>{std.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      )
    }
}
TypeDocumentSelect.propTypes = {
    type_documents: PropTypes.array,
    callback: PropTypes.func
}

export default TypeDocumentSelect

