/*
Display a Person associate to a Document
*/

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ShowChargePerson extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
    }

    handleDestroy(event) {
        const confirmation = confirm('Estas Seguro que queres eliminar a la Persona')
        if (confirmation) {
            this.props.onDelete(this.props)
        }
        event.preventDefault();
    }


    render() {
        const {relation} = this.props;
        return (
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-secondary border-bottom-secondary shadow h-100 py-2">
                      <div className="card-body">
                          <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                  <div className="h5 text-uppercase mb-1">
                                      <a className="text-success" href={"/dashboard/people/" + relation.id}>{ relation.name}</a>
                                  </div>
                                  { relation.dni_number && (
                                    <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                      <strong> Nº Doc.: </strong>{ relation.dni_number }
                                    </div>
                                  )}
                                  { relation.phone && (

                                    <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                        { relation.phone}
                                    </div>
                                  )}
                                  { relation.charge_name && (
                                    <div className="text-xs font-weight-bold mb-0 font-weight-bold text-gray-800">
                                        { relation.charge_name}
                                    </div>
                                  )}
                              </div>
                              <div className="col-auto">
                                  <button
                                        onClick={this.handleDestroy}
                                        className="btn btn-outline-danger btn-sm"
                                    >
                                        <i className="fas fa-unlink"></i>
                                    </button>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
        )
    }
}

export default ShowChargePerson

ShowChargePerson.propTypes = {
    relation: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
}
