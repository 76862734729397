import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ItemForm extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeCondition = this.handleChangeCondition.bind(this)
        this.state = {
            name: props.rule.name,
            description: props.rule.description,
            condition: props.rule.condition,
            destroy: 0
        }
    }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeDescription(event) {
    this.setState({description: event.target.value});
  }
  handleChangeCondition(event) {
    this.setState({condition: event.target.value});
  }

    handleDestroy(event) {
        if(this.props.rule.id < 0)
            this.props.onDelete(this.props)
        else{
            const confirmation = confirm('Estas Seguro de eliminar la Regla?')
            if (confirmation) {
                this.setState({ destroy: 1 })
            }
        }
        event.preventDefault();
    }

    field_name(id, name) {
        const container = this.props.field_name
        return `${container}[${id}][${name}]`
    }

    render() {
        const { rule } = this.props
        const renderDestroy = (rule)=> {
            if(rule.id > 0){
                return  <React.Fragment>
                          <label>
                            <input type='checkbox' name={this.field_name(rule.id, '_destroy')} defaultValue="1" />
                            Eliminar
                          </label>
                        </React.Fragment>
            } else{
                return  <React.Fragment>
                          <button
                            onClick={this.handleDestroy}
                            className="btn btn-outline-danger"
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </React.Fragment>
            }
        }

        const renderHiddenId = (rule)=> {
            if(rule.id > 0){
              return  <React.Fragment>
                        <input type='hidden' name={this.field_name(rule.id, 'id')} value={rule.id} />
                      </React.Fragment>
            } else{
                return ""
            }
        }

        return (
          <div className="card">
            {renderHiddenId(rule)}
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Nombre</div>
                      </div>
                      <input type="text" className="form-control" name={this.field_name(rule.id, 'name')} value={this.state.name} onChange={this.handleChangeName} />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Descripción</div>
                      </div>
                      <input type="text" className="form-control" name={this.field_name(rule.id, 'description')} defaultValue={this.state.description} onChange={this.handleChangeDescription}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <select className="form-control filter form-control-sm" name={this.field_name(rule.id, 'condition')} onChange={ this.handleChangeCondition }>
                    {this.props.rulesConditions.map((rule) => {
                      return <option value={rule[1]} key={`"rules_${rule[1]}"`}>{rule[0]}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    {renderDestroy(rule)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default ItemForm

ItemForm.propTypes = {
    rule: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    rulesConditions: PropTypes.array
}
