
import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'
import {DelayInput} from 'react-delay-input';

class BrowseDocuments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            documents: props.documents || [],
            disable_ids: props.disable_ids || [],
            isLoading: false,
            errorMessage: null,
            term: "",
            type: "",
            start_at: "",
            end_at: "",
        }
        this.handleChangeTerm = this.handleChangeTerm.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleChangeStartAt = this.handleChangeStartAt.bind(this)
        this.handleChangeEndAt = this.handleChangeEndAt.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.selectDocument = this.selectDocument.bind(this)
        this.clearEmissionAt = this.clearEmissionAt.bind(this)
    }

    componentDidMount() {

      if(this.props.withInitialDocuments){
        this.handleFilter({})
      }

    }

    handleChangeTerm(event){
      let data = {term: event.target.value, start_at: this.state.start_at, end_at: this.state.end_at, type_document_id: this.state.type_document_id }
      this.setState({term: event.target.value})
      this.handleFilter(data)
    }

    handleChangeType(event){
      let data = {term: this.state.term, start_at: this.state.start_at, end_at: this.state.end_at, type_document_id: event.target.value }
      this.setState({type_document_id: event.target.value})
      this.handleFilter(data)
    }

    buttonTitle() {
      return this.props.buttonTitle || "Seleccionar Documentos"
    }
    handleChangeStartAt(event){
      let data = {term: this.state.term, start_at: event.target.value, end_at: this.state.end_at, type_document_id: this.state.type_document_id }
      this.setState({start_at: event.target.value})
      this.handleFilter(data)
    }

    handleChangeEndAt(event){
      let data = {term: this.state.term, start_at: this.state.start_at, end_at: event.target.value, type_document_id: this.state.type_document_id }
      this.setState({end_at: event.target.value})
      this.handleFilter(data)
    }

    handleFilter(data){
      let el = this
      $.ajax({
        type: "GET",
        url: `\\dashboard\\documents\\search.json`,
        data: data,
        success: function( data, textStatus, jqXHR){
          const documents = data
          el.setState({ documents })
        },
        dataType: 'json'
      });
    }

    isDisabled(id){
      let found = this.state.disable_ids.filter(document_id =>
        document_id == id);
      return (found === undefined || found == null || found.length <= 0) ? false : true;
    }
    selectDocument(event, document){
      this.props.addDocument(document)
      let disable_ids = [document.id, ...this.state.disable_ids]
      this.setState({ disable_ids: disable_ids })

      event.preventDefault()
    }

    clearEmissionAt(){
      flatpickr("#emission_at").clear()
      let data = {term: this.state.term, type_document_id: this.state.type_document_id }
      this.setState({emission_at: []})
      this.handleFilter(data)
    }

    render() {
      return (
        <React.Fragment>
          <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#browseModalDocuments">
            { this.buttonTitle() }
          </button>
          <div className="modal fade" tabIndex="-1" role="dialog" id="browseModalDocuments">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Seleccionar Documento</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="card">
                        <div className="card-header">
                          Filtrar
                        </div>
                        <div className="card-body">
                          <div className="form-group">
                            <label className="control-label"><strong>Ingresar Texto</strong></label>
                            <DelayInput
                              minLength={2}
                              delayTimeout={ 300 }
                              className="form-control form-control-sm"
                              onChange={ this.handleChangeTerm } />
                          </div>
                          <div className="form-group">
                            <label className="control-label"><strong>Tipo</strong></label>
                              <select className="form-control filter form-control-sm" name='filter_type_documents' key='filter_type_documents' onChange={ this.handleChangeType }>
                                <option value="" key="option_type_documents_0"></option>;
                                {this.props.options_type_documents.map((type_document) => {
                                  return <option value={type_document[1]} key={`"option_type_documents_${type_document[1]}"`}>{type_document[0]}</option>;
                                })}
                              </select>
                          </div>
                          <div className="form-group">
                            <label className="control-label">Inicio</label>
                            <input type="date" className="form-control form-control-sm filter" onChange={this.handleChangeStartAt } />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Fin</label>
                            <input type="date" className="form-control form-control-sm filter" onChange={this.handleChangeEndAt } />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="row">

                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table id="index-documents-react"
                                   className="table table-striped responsive-utilities jambo_table"
                                   width="100%">
                              <thead>
                                <tr className="headings">
                                  <th>Número</th>
                                  <th>Título</th>
                                  <th>Fecha</th>
                                  <th>Descripción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.documents.map((document) => {
                                  if(!this.isDisabled(document.id)){
                                    return (<tr key={document.id}>
                                      <td>
                                        <button className="btn btn-sm btn-success" onClick={(event) => this.selectDocument(event, document)}>
                                          <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                          </button>
                                      </td>

                                      <td>{ document.number }</td>
                                      <td>{ document.name }</td>
                                      <td>{ document.emission_at }</td>
                                    </tr>)
                                    }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

        )
    }
}
BrowseDocuments.propTypes = {
    addDocument: PropTypes.func.isRequired,
}

export default BrowseDocuments

